import Image from "next/future/image";

export default function ImageGliph({ src }) {
  return (
    <span className="glyph-image-container">
      <Image
        layout="raw"
        width="200"
        height="200"
        src={`/assets/animated-glyphs/${src}.gif`}
      />
    </span>
  );
}
