import MetaTags from "components/MetaTags";
import { ContainerHomepage } from "components/Elements/Container";
import { ProjectCard, NewsCard } from "components/GridCards";
import ButtonTicker from "components/Elements/ButtonTicker";
import AnimatedBox from "components/Elements/AnimatedBox";
import API from "strapi/api";
import ImageGliph from "components/Elements/ImageGlliph";

export default function Home(props) {
  return (
    <>
      <MetaTags
        title={
          props.meta_info && props.meta_info.meta_title
            ? props.meta_info.meta_title
            : "An Independent Animation Studio"
        }
        description={
          props.meta_info && props.meta_info.meta_description
            ? props.meta_info.meta_description
            : "ZANAD | An Independent Animation Studio"
        }
        image={
          props.meta_info && props.meta_info.og_image
            ? API.getNextImage(props.meta_info.og_image, "large")
            : "/public/og-image.jpg"
        }
      />
      <main>
        <h1 className="offscreen-title">
          {props.meta_info && props.meta_info.meta_title
            ? props.meta_info.meta_title
            : "ZANAD | An Independent Animation Studio"}
        </h1>

        <ContainerHomepage>
          <p className="mb-6 uppercase fluid-type">
            <AnimatedBox num={1}>
              <span className="flex flex-row justify-start">
                <span className="optical-align letter-b">
                  <AnimatedBox num={3.5}>Bad</AnimatedBox>
                  <AnimatedBox num={1}>
                    <ImageGliph src="MARGIN_BOLT" />
                  </AnimatedBox>
                  <AnimatedBox num={7}>ideas </AnimatedBox>
                  <AnimatedBox num={2.5}>die</AnimatedBox>
                </span>
              </span>
            </AnimatedBox>
            <AnimatedBox num={2}>
              <span className="flex flex-row justify-between">
                <AnimatedBox num={5.5}>
                  <span>young.</span>
                </AnimatedBox>
                <span>
                  <span className="block 640:hidden">
                    <br />
                  </span>
                  <AnimatedBox num={1.5}>We </AnimatedBox>
                  <AnimatedBox num={3.5}>make</AnimatedBox>
                </span>
              </span>
            </AnimatedBox>
            <AnimatedBox num={3}>
              <span className="flex flex-row justify-end">
                <span>
                  <AnimatedBox num={3}>them</AnimatedBox>
                  <AnimatedBox num={6}>
                    <ImageGliph src="MARGIN_SKULL" />
                  </AnimatedBox>
                  <AnimatedBox num={1.5}>immortal.</AnimatedBox>
                </span>
              </span>
            </AnimatedBox>
          </p>
          <div className="relative gap-x-5 gap-y-8 grid grid-cols-12">
            {props.content.homepage_work ? (
              props.content.homepage_work.map((work, i) => {
                return work.url || work.outside_url ? (
                  <ProjectCard
                    imageUrl={API.getNextImage(work.image, "large")}
                    title={work.project_title}
                    client={work.client}
                    year={work.year}
                    projectLink={work.url}
                    outsideURL={work.outside_url}
                    index={i}
                    key={i}
                    videoDesktop={work.video_large}
                    videoMobile={work.video_small}
                    size={work.size}
                  />
                ) : (
                  <NewsCard
                    imageUrl={API.getNextImage(work.image, "large")}
                    title={work.project_title}
                    index={6}
                    key={i}
                    videoDesktop={work.video_large}
                    videoMobile={work.video_small}
                    size={work.size}
                  />
                );
              })
            ) : (
              <></>
            )}
          </div>
          <p className="mt-8 mb-6 uppercase fluid-type">
            <span className="flex flex-row justify-start">
              <span>
                <AnimatedBox num={1}>The </AnimatedBox>
                <AnimatedBox num={5.5}>international</AnimatedBox>
              </span>
            </span>
            <span className="flex flex-row justify-end">
              <span>
                <AnimatedBox num={3}>
                  <ImageGliph src="MARGIN_GLOBE" />
                </AnimatedBox>
                <AnimatedBox num={6}>independent </AnimatedBox>
                <AnimatedBox num={1.5}>animation</AnimatedBox>
              </span>
            </span>
            <span className="flex flex-row justify-start">
              <span className="inline-block optical-align letter-s">
                <AnimatedBox num={3.5}>studio</AnimatedBox>
              </span>
              <span className="inline-block">
                <AnimatedBox num={6.6}>
                  <ButtonTicker
                    title="About Us"
                    url="/about"
                    external={false}
                  />
                </AnimatedBox>
              </span>
            </span>
            <AnimatedBox num={2}>
              <span className="flex flex-row justify-end">
                <span>
                  <AnimatedBox num={6.5}>that </AnimatedBox>
                  <AnimatedBox num={5}>ignites</AnimatedBox>
                  <AnimatedBox num={1.5}>
                    <ImageGliph src="MARGIN_WIZARD" />
                  </AnimatedBox>
                </span>
              </span>
            </AnimatedBox>
            <AnimatedBox num={2}>
              <span className="flex flex-row justify-start">
                <span className="optical-align letter-b">
                  <AnimatedBox num={2.5}>untamed </AnimatedBox>
                  <AnimatedBox num={7}>ideas </AnimatedBox>
                  <AnimatedBox num={3}>globally.</AnimatedBox>
                </span>
              </span>
            </AnimatedBox>
          </p>
        </ContainerHomepage>
      </main>
    </>
  );
}

export async function getStaticProps(ctx) {
  const content = await API.getSingleType("homepage");

  return {
    props: {
      content: content,
    },
    revalidate: 10,
  };
}
