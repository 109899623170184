import { useState } from "react";
import Link from "next/link";
import Marquee from "react-fast-marquee";
import ImageWithTransition from "components/Elements/ImageWithTransition";
import VideoLazy from "components/Elements/VideoLazy";

export const ProjectCard = (props) => {
  const [isShown, setIsShown] = useState(false);

  return props.outsideURL ? (
    <div
      className={`card ${
        props.size == "Large"
          ? "1024:col-span-8 col-span-12"
          : "1024:col-span-4 col-span-12"
      }`}
    >
      <a
        href={props.outsideURL}
        className="group"
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        target="_blank"
        rel="noreferrer"
      >
        <div className="relative image-container aspect-video">
          {props.videoDesktop && props.videoMobile ? (
            <VideoLazy
              videoDesktop={props.videoDesktop}
              videoMobile={props.videoMobile}
            />
          ) : (
            <ImageWithTransition
              src={props.imageUrl}
              objectFit="cover"
              layout="fill"
              alt=""
            />
          )}
          <div className="absolute top-0 left-0 w-full h-full transition-opacity duration-300 opacity-0 card-overlay ease-easeInOutQuint group-hover:opacity-100">
            <div className="absolute top-0 left-0 w-full h-full overlay-bg bg-black/40" />
            {/* <div className='absolute -translate-y-1/2 top-1/2'> */}
            <Marquee
              gradient={false}
              play={isShown}
              speed={80}
              direction={props.index % 2 == 0 ? "left" : "right"}
            >
              <p className="text-white uppercase">
                {props.title ? (
                  <span className="inline-block mr-2 align-top fluid-type-medium">
                    {props.title}
                  </span>
                ) : (
                  <></>
                )}

                <span className="inline-block mr-2 align-top text-18">
                  view
                  <span className="relative -top-0.5 ml-1">↗</span>
                </span>

                {props.title ? (
                  <span className="inline-block mr-2 align-top fluid-type-medium">
                    {props.title}
                  </span>
                ) : (
                  <></>
                )}

                <span className="inline-block mr-2 align-top text-18">
                  view
                  <span className="relative -top-0.5 ml-1">↗</span>
                </span>
                <span className="inline-block mr-2 align-top fluid-type-medium">
                  {props.title}
                </span>
                <span className="inline-block mr-2 align-top text-18">
                  view
                  <span className="relative -top-0.5 ml-1">↗</span>
                </span>
              </p>
            </Marquee>
            {/* </div> */}
          </div>
        </div>
        <div className="flex justify-between gap-x-[20px] mt-2 leading-6 uppercase project-info text-16 768:text-18 1280:text-22">
          <div className="w-full">
            {props.title ? <h2>{props.title}</h2> : <></>}
            {props.client ? <span>{props.client}</span> : <></>}
          </div>

          {props.year ? (
            <div className="w-auto">
              <span>{props.year}</span>
            </div>
          ) : (
            <>
              <div className="fluid-type-mini flex items-center ml-auto h-[28px] w-[56px] min-w-[56px] 768:h-[32px] 768:w-[70px] 768:min-w-[70px] whitespace-nowrap rounded-[50%] bg-black border-black border-[3px] text-white uppercase overflow-hidden mb-10">
                <span className="btn-ticker-inner-v3">
                  <span data-text="News">News</span>
                </span>
              </div>
            </>
          )}
        </div>
      </a>
    </div>
  ) : (
    <div
      className={`card ${
        props.size == "Large"
          ? "1024:col-span-8 col-span-12"
          : "1024:col-span-4 col-span-12"
      }`}
    >
      <Link prefetch={false} scroll={false} href={props.projectLink}>
        <a
          className="group"
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          <div className="relative image-container aspect-video">
            {props.videoDesktop && props.videoMobile ? (
              <VideoLazy
                videoDesktop={props.videoDesktop}
                videoMobile={props.videoMobile}
              />
            ) : (
              <ImageWithTransition
                src={props.imageUrl}
                objectFit="cover"
                layout="fill"
                alt=""
              />
            )}
            <div className="absolute top-0 left-0 w-full h-full transition-opacity duration-300 opacity-0 card-overlay ease-easeInOutQuint group-hover:opacity-100">
              <div className="absolute top-0 left-0 w-full h-full overlay-bg bg-black/40" />
              {/* <div className='absolute -translate-y-1/2 top-1/2'> */}
              <Marquee
                gradient={false}
                play={isShown}
                speed={80}
                direction={props.index % 2 == 0 ? "left" : "right"}
              >
                <p className="text-white uppercase">
                  {props.title ? (
                    <span className="inline-block mr-2 align-top fluid-type-medium">
                      {props.title}
                    </span>
                  ) : (
                    <></>
                  )}

                  <span className="inline-block mr-2 align-top text-18">
                    view
                    <span className="relative -top-0.5 ml-1">↗</span>
                  </span>

                  {props.title ? (
                    <span className="inline-block mr-2 align-top fluid-type-medium">
                      {props.title}
                    </span>
                  ) : (
                    <></>
                  )}

                  <span className="inline-block mr-2 align-top text-18">
                    view
                    <span className="relative -top-0.5 ml-1">↗</span>
                  </span>
                  <span className="inline-block mr-2 align-top fluid-type-medium">
                    {props.title}
                  </span>
                  <span className="inline-block mr-2 align-top text-18">
                    view
                    <span className="relative -top-0.5 ml-1">↗</span>
                  </span>
                </p>
              </Marquee>
              {/* </div> */}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-0 mt-2 leading-6 uppercase project-info text-16 768:text-18 1280:text-22">
            <div className="col-span-8 991:col-span-10">
              {props.title ? <h2>{props.title}</h2> : <></>}
              {props.client ? <span>{props.client}</span> : <></>}
            </div>
            {props.year ? (
              <div className="col-span-4 991:col-span-2 text-right">
                <span>{props.year}</span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </a>
      </Link>
    </div>
  );
};

export const NewsCard = (props) => {
  return (
    <div
      className={`card ${
        props.size == "Large"
          ? "1024:col-span-8 col-span-12"
          : "1024:col-span-4 col-span-12"
      }`}
    >
      <div className="image-container">
        {props.videoDesktop && props.videoMobile ? (
          <VideoLazy
            videoDesktop={props.videoDesktop}
            videoMobile={props.videoMobile}
          />
        ) : (
          <ImageWithTransition src={props.imageUrl} layout="fill" alt="" />
        )}
      </div>
      <div className="mt-2 leading-6 uppercase project-info text-16 768:text-18 1280:text-22">
        <h2>{props.title}</h2>
      </div>
    </div>
  );
};
