import { useState } from 'react'
import Image from 'next/image'

const ImageWithTransition = ({ src, layout, sizes, alt = '', classes, objectFit, loading = 'lazy', priority = false, quality = 75 }) => {
    const [isLoading, setIsLoading] = useState(true)

    const onLoadingComplete = () => setIsLoading(false)

    return (
        <Image
            className={`${classes} transition-all duration-500 ${isLoading ? 'opacity-0' : 'opacity-100'}`}
            src={src}
            layout={layout}
            onLoadingComplete={onLoadingComplete}
            objectFit={objectFit}
            priority={priority}
            quality={quality}
            loading={loading}
            alt={alt}
            sizes={sizes}
        />
    )
}

export default ImageWithTransition
