import Bowser from "bowser";
import { useEffect, useState } from "react";

export default function VideoLazy({ videoDesktop, videoMobile }) {
  const [platform, setPlatform] = useState(null);

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    setPlatform(browser.getPlatform().type);
  }, []);

  useEffect(() => {
    var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
    if ("IntersectionObserver" in window) {
      var lazyVideoObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (video) {
          if (video.isIntersecting) {
            for (var source in video.target.children) {
              var videoSource = video.target.children[source];
              if (
                typeof videoSource.tagName === "string" &&
                videoSource.tagName === "SOURCE"
              ) {
                videoSource.src = videoSource.dataset.src;
              }
            }

            video.target.load();
            video.target.classList.remove("lazy");
            lazyVideoObserver.unobserve(video.target);
          }
        });
      });

      lazyVideos.forEach(function (lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
      });
    }
  }, []);

  return (
    <video
      preload="metadata"
      className="lazy object-center h-full w-full object-cover"
      autoPlay
      muted
      playsInline
      loop
    >
      <source
        data-src={platform === "desktop" ? videoDesktop : videoMobile}
        type="video/mp4"
      />
    </video>
  );
}
